<template>
  <CompetitionForm v-bind="props" :form="getSingleCompetition"/>
</template>

<script>
import CompetitionForm from "@/components/forms/CompetitionForm.vue";
import { mapGetters } from "vuex";
export default {
  components: { CompetitionForm },
  computed: {
    ...mapGetters(["getUser", "getSingleCompetition"]),
    form: {
      get() {
        return this.getSingleCompetition;
      },
    },
  },
  created(){
    if(this.$store.getters.checkToken.isFederation){
      this.$store.dispatch("initSingleCompetition",this.$route.params.id);
    }
  },
  data() {
    return {
      props: {
        title: "Yarışmayı Güncelle",
        submit: (form) => {
          this.$store.dispatch("updateCompetition",form).then(()=>this.$router.push("/competitions"));
        },
      },
    };
  },

};
</script>
<style scoped>
</style>