<template>
  <b-container class="mt-4">
    <b-form @submit="onSubmit">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="title" class="mr-2 text-white"></label>
          <b-button variant="dark" class="mr-2" to="/competitions"
            >Yarışmalar</b-button
          >
          <b-button variant="primary" type="submit">Gönder</b-button>
        </template>
        <b-alert class="spacing" show>
          Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
        </b-alert>
        <b-row>
          <b-col>
            <InputTile label="Branş" :custom="true">
              <b-form-select
                v-model="form.branch"
                :options="getBranches"
                text-field="title"
                value-field="id"
                required
              >
              </b-form-select>
            </InputTile>
            <InputTile label="Lokasyon" :custom="true">
              <b-form-select v-model="form.location" :options="locOptions">
              </b-form-select>
            </InputTile>
            <InputTile label="Tür" :custom="true">
              <b-form-select
                v-model="form.kind"
                :options="getTypes"
                text-field="title"
                value-field="id"
                required
              >
              </b-form-select>
            </InputTile>
            <InputTile label="Kategori" :custom="true">
              <b-row>
                <b-col>
                  <b-form-checkbox-group
                    v-model="selectedCategories"
                    :options="getManagementItems.filter((e) => e.category == 2)"
                    value-field="id"
                    text-field="title"
                    stacked
                  ></b-form-checkbox-group>
                </b-col>
              </b-row>
            </InputTile>

            <InputTile
              label="Adı"
              v-model="form.name"
              :required="true"
              type="text"
            />
            <InputTile
              label="Şehir"
              v-model="form.place"
              :required="true"
              type="text"
            />
          </b-col>
          <b-col>
            <InputTile label="PDF Adresi" :custom="true">
              <b-form-file
                v-model="selectedFile"
                :placeholder="selectedFileName"
                drop-placeholder="Buraya bırakın..."
                browse-text="Dosya Seç"
              ></b-form-file>
            </InputTile>
            <InputTile
              label="Sporcu Alt Yaş Sınırı"
              v-model="form.lowerAgeLimit"
              type="number"
            />
            <InputTile
              label="Sporcu Üst Yaş Sınırı"
              v-model="form.upperAgeLimit"
              type="number"
            />
            <InputTile
              label="Başlangıç Tarihi"
              v-model="form.startingDate"
              :required="true"
              type="date"
            />
            <InputTile
              label="Bitiş Tarihi"
              v-model="form.endingDate"
              type="date"
            />
            <InputTile label="Sonuç Girilebilir" :custom="true">
              <b-checkbox v-model="form.resultCanBeEntered"> </b-checkbox>
            </InputTile>
            <InputTile label="Açıklama" :custom="true">
              <b-textarea
                v-model="form.description"
                rows="3"
                max-rows="3"
              ></b-textarea>
            </InputTile>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import InputTile from "./common/InputTile.vue";
import { mapGetters } from "vuex";
import locationOptions from "../../store/constants/locationOptions";

export default {
  components: { InputTile },
  name: "CompetitionForm",
  computed: {
    ...mapGetters(["getManagementItems"]),
    getTypes: {
      get() {
        return [{ title: "Seçin", id: "" }].concat(
          this.getManagementItems.filter((e) => {
            return e.category == 7; //TYPE FILTER
          })
        );
      },
    },
    getBranches: {
      get() {
        return [{ title: "Seçin", id: "" }].concat(
          this.getManagementItems.filter((e) => {
            return e.category == 1; //TYPE FILTER
          })
        );
      },
    },
  },
  data() {
    return {
      selectedCategories: [],
      selectedFile: null,
      selectedFileName: "Dosya seçin veya buraya sürükleyin...",
      locOptions: locationOptions,
    };
  },
  props: ["title", "submit", "form"],
  beforeUpdate() {
    if (this.form.pdfAddress) this.selectedFileName = "Hazırda Var";
  },
  created() {
    this.$store
      .dispatch("initManagement")
      .then(() => (this.selectedCategories = this.form.category.split(";")));
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.selectedFile) {
        this.form.changepdfAddress = this.form.pdfAddress;
        this.form.pdfAddress = this.selectedFile;
      }
      this.form.category = this.selectedCategories.join(";");
      this.submit(this.form);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
.buttonBar {
  text-align: end;
}
.center {
  text-align: center;
}
.labelContainer {
  display: grid;
  place-items: left;
}
.spacing {
  margin-top: 0.5rem;
}
</style>